import { InMemoryCache } from "@apollo/client";
import { offsetLimitPagination } from "@apollo/client/utilities";

const cache = new InMemoryCache({
  typePolicies: {
    PromotionData: {
      keyFields: ["promotionId"],
    },
    ScheduledJobs: {
      keyFields: ["jobId"],
    },
    MemberTypeClubPermissionData: {
      keyFields: ["memberTypeClubPermissionId"],
    },
    ClubWithPermission: {
      keyFields: ["clubId"],
    },
    FeatureWithPermissionDataOutput: {
      keyFields: ["featureId"],
    },
    FeatureDataType: {
      keyFields: ["featureId"],
    },
    FeatureClubCategory: {
      keyFields: ["clubCategoryId"],
    },
    UpcomingEvents: {
      keyFields: ["eventId"],
    },
    Interstitials: {
      keyFields: ["interstitialsId"],
    },
    TourKeys: {
      keyFields: ["tourId"],
    },
    AB_ReleasePanel: {
      keyFields: ["releaseId"],
    },
    PopUpAdminPanel: {
      keyFields: ["popUpId"],
    },
    Badges: {
      keyFields: ["badgeId"],
    },
    WhatsNew: {
      keyFields: ["whatsNewId"],
    },
    WhatsNewData: {
      keyFields: ["id"],
    },
    FeedbackQuestions: {
      keyFields: ["questionId"],
    },
    SiteReport: {
      keyFields: ["ReportId"],
    },
    ApprovalRequests: {
      keyFields: ["approvalRequestId"],
    },
    AdminNotificationOutput: {
      keyFields: ["notificationId"],
    },
    Hashtag: {
      keyFields: ["TagId"],
    },
    Query: {
      fields: {
        adminSuspectedEntities: offsetLimitPagination(),
        adminSearchText: offsetLimitPagination([
          "searchText",
          "type",
          "SearchFilter",
        ]),
        adminUserSearch: offsetLimitPagination(["email", "username"]),
        getAdminNotificationList: offsetLimitPagination(["filter"]),
      },
    },
  },
});

export default cache;
