import { useMemo } from "react";
import { ApolloClient, createHttpLink, from, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import cache from "config/cache";
import { getOIDCUser } from "config/auth";


export const useClient = () => {
  return useMemo(() => {
    const httpLink = createHttpLink({
      uri: process.env.GRAPHQL_API_URL,
    });

    const authLink = setContext((_, { headers }) => {
      const user = getOIDCUser();
      const token = user?.access_token;
      if (token) {
        return {
          headers: {
            ...headers,
            authorization: `Bearer ${token}`,
          },
        };
      } else {
        return { headers };
      }
    });

    const wsLink = new GraphQLWsLink(
      createClient({
        url: process.env.GRAPHQL_API_URL as string,
        connectionParams: () => {
          const user = getOIDCUser();
          const accessToken = user?.access_token;
          if (accessToken)
            return {
              Authorization: `Bearer ${accessToken}`,
            };
          return {};
        },
      })
    );

    const link = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      wsLink,
      from([authLink, httpLink])
    );

    return new ApolloClient({
      link,
      cache,
    });
  }, []);
};
