import React, { lazy, Suspense } from "react";
import { withAdminRoleRequired } from "config/auth";
import { keyRoleMap } from "config/side-menu-items";
import { Route, Routes } from "react-router-dom";

const SearchUsers = lazy(() => import("pages/developer-settings/users"));
const BlueTickUsers = lazy(
  () => import("pages/developer-settings/users/BlueTickUsers")
);
const GreenTickUsers = lazy(
  () => import("pages/developer-settings/users/GreenTickUsers")
);
const AllClubs = lazy(() => import("pages/developer-settings/clubs/AllClubs"));
const ExternalResource = lazy(
  () => import("pages/developer-settings/externalResource")
);
const JobList = lazy(
  () => import("pages/developer-settings/jobs/ScheduleJobs")
);
const ProductTour = lazy(() => import("pages/developer-settings/productTour"));
const BadgeSystem = lazy(() => import("pages/developer-settings/badgeSystem"));
const ABTesting = lazy(() => import("pages/developer-settings/abtesting"));
const BadgePopups = lazy(() => import("pages/developer-settings/badgePopups"));
const PendingScheduledJobs = lazy(
  () => import("pages/developer-settings/jobs/PendingScheduledJobs")
);
const JobHistory = lazy(
  () => import("pages/developer-settings/jobs/JobHistory")
);
const PriorityProfiles = lazy(
  () => import("pages/developer-settings/PriorityProfiles")
);

const PlatformFeatures = React.lazy(
  () => import("pages/developer-settings/feature/platform")
);
const ClubFeatures = React.lazy(
  () => import("pages/developer-settings/feature/club")
);

const OnboardingHashtagCategorization = React.lazy(
  () => import("pages/developer-settings/onboarding/hashtag-categorization")
);
const OnboardingTickUsersCategorization = React.lazy(
  () => import("pages/developer-settings/onboarding/tick-users-categorization")
);

const ClubCategories = React.lazy(
  () => import("pages/developer-settings/clubs/ClubCategories")
);
const WhatsNew = React.lazy(() => import("pages/developer-settings/whats-new"));
const BadgeFeedback = React.lazy(
  () => import("pages/developer-settings/badgeSystem/BadgeFeedback")
);
const PreferredHashtags = React.lazy(
  () => import("pages/developer-settings/PreferredHashtags")
);

const DeveloperSettings = () => {
  return (
    <Routes>
      <Route path="users">
        <Route
          path="search-users"
          element={
            <Suspense fallback={<></>}>
              <SearchUsers />
            </Suspense>
          }
        />
        <Route
          path="blue-tick-users"
          element={
            <Suspense fallback={<></>}>
              <BlueTickUsers />
            </Suspense>
          }
        />
        <Route
          path="green-tick-users"
          element={
            <Suspense fallback={<></>}>
              <GreenTickUsers />
            </Suspense>
          }
        />
      </Route>
      <Route path="clubs">
        <Route
          index
          element={
            <Suspense fallback={<></>}>
              <AllClubs />
            </Suspense>
          }
        />
        <Route
          path="categories"
          element={
            <Suspense fallback={<></>}>
              <ClubCategories />
            </Suspense>
          }
        />
      </Route>
      <Route
        path="external-resource"
        element={
          <Suspense fallback={<></>}>
            <ExternalResource />
          </Suspense>
        }
      />

      <Route path="jobs/*">
        <Route
          path="schedule-jobs"
          element={
            <Suspense fallback={<></>}>
              <JobList />
            </Suspense>
          }
        />
        <Route
          path="pending-schedule-jobs"
          element={
            <Suspense fallback={<></>}>
              <PendingScheduledJobs />
            </Suspense>
          }
        />
        <Route
          path="job-history"
          element={
            <Suspense fallback={<></>}>
              <JobHistory />
            </Suspense>
          }
        />
      </Route>
      <Route path="features">
        <Route
          path="platform"
          element={
            <Suspense fallback={<></>}>
              <PlatformFeatures />
            </Suspense>
          }
        />
        <Route
          path="club"
          element={
            <Suspense fallback={<></>}>
              <ClubFeatures />
            </Suspense>
          }
        />
      </Route>

      <Route path="onboarding">
        <Route
          path="hashtag-categorization"
          element={
            <Suspense fallback={<></>}>
              <OnboardingHashtagCategorization />
            </Suspense>
          }
        />
        <Route
          path="tick-users-categorization"
          element={
            <Suspense fallback={<></>}>
              <OnboardingTickUsersCategorization />
            </Suspense>
          }
        />
      </Route>
      <Route
        path="product-tour"
        element={
          <Suspense fallback={<></>}>
            <ProductTour />
          </Suspense>
        }
      />
      <Route path="badge-system/*">
        <Route
          index
          element={
            <Suspense fallback={<></>}>
              <BadgeSystem />
            </Suspense>
          }
        />
        <Route
          path={":badgeId/badge-feedback-questions"}
          element={
            <Suspense fallback={<></>}>
              <BadgeFeedback />
            </Suspense>
          }
        />
      </Route>
      <Route
        path="ab-testing"
        element={
          <Suspense fallback={<></>}>
            <ABTesting />
          </Suspense>
        }
      />
      <Route
        path="badge-popups"
        element={
          <Suspense fallback={<></>}>
            <BadgePopups />
          </Suspense>
        }
      />
      <Route
        path="priority-profiles"
        element={
          <Suspense fallback={<></>}>
            <PriorityProfiles />
          </Suspense>
        }
      />
      <Route
        path="whats-new"
        element={
          <Suspense fallback={<></>}>
            <WhatsNew />
          </Suspense>
        }
      />
      <Route
        path="preferred-hashtags"
        element={
          <Suspense fallback={<></>}>
            <PreferredHashtags />
          </Suspense>
        }
      />
    </Routes>
  );
};

export default withAdminRoleRequired(DeveloperSettings, {
  roles: keyRoleMap["Developer Settings"],
});
